import React from "react";
import { GlobalStyles } from "../components/ui/GlobalStyles";
import styled from "styled-components";
import { colors } from "../lib/constant";
import { HeaderNavigation } from "../components/HeaderNavigation";
import { Button } from "../components/ui/Button";
import { IconArrowRight14 } from "../icons-ts";
import { fontSize, fontFamily, product_sans } from "../lib/fonts";

const IlluError404 = require("../../assets/illustrations/error.svg");

const Background = styled.div`
  position: fixed;
  background: linear-gradient(
    to left,
    ${colors.primary} 0%,
    ${colors.primaryLt} 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorBox = styled.div`
  max-width: 510px;
  padding: 60px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  margin: 60px 0;
  color: #fff;
  ${fontSize("24px")};
  ${fontFamily(product_sans.bold)};
  line-height: 34px;
`;

const ErrorImage = styled.img`
  display: block;
  width: 100%;
`;

const Page404 = () => {
  return (
    <>
      <GlobalStyles />
      <HeaderNavigation scrollEnabled={false} />
      <Background>
        <ErrorBox>
          <ErrorImage src={IlluError404} alt="Image Error 404" />
          <ErrorMessage>
            La page que vous cherchez n'existe pas ou a été supprimée.
          </ErrorMessage>
          <a href="../">
            <Button icon={<IconArrowRight14 width="14" fill="#fff" />}>
              Retour à l'accueil
            </Button>
          </a>
        </ErrorBox>
      </Background>
    </>
  );
};

export default Page404;
